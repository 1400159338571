<template>
  <div class="subBg">
     <section class="w1314">
       <div class="snavi">
         <!--img class="mr10 ml10" src="@/assets/img/snavhomeicon.png" />
         <img src="@/assets/img/snavicon.png" />
         <span class="ml10 b">{{ $t('front.common.board') }}</span-->
       </div>
       <h2 class="subBanner"><img src="@/assets/img/icon_vvs.svg" alt=""/>{{ $t('front.common.board') }}</h2>
       <router-view :key="$route.params"/>
     </section>
  </div>
</template>

<script>
export default {
  name: 'Board'
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
